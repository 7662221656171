
  #components-layout-demo-top-side-2 .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
  }
  
  .ant-row-rtl #components-layout-demo-top-side-2 .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }
  .ant-layout{
    /* background-color: rgb(187, 5, 5); */
    height: 100vh;
  }
  .site-layout-background {
    background: rgb(255, 255, 255);
    box-shadow: 0px 2px 18px -10px rgba(0,0,0,0.75);
  }

  .site-page-header-ghost-wrapper{
    box-shadow: 0px 2px 18px -10px rgba(0,0,0,0.75);
  }

.ant-btn-primary {
    background-color: #231a52;
    color: #fff;
    border: 1px solid rgb(218, 209, 209);
    outline: none;
  }
  .ant-btn-primary:hover,
  .ant-btn-danger:hover,
  .ant-btn-primary:focus ,
  .ant-btn-primary:active  {
    background-color: #231a52;
    color: #fff;
    border: 1px solid #231a52;
  }
.ant-btn-primary:hover {
    background-color: #d80909de;
    color: #fff;
    border: 1px solid rgb(218, 209, 209);
    outline: none;
    box-shadow: 0px 2px 18px -10px rgba(0,0,0,0.75);
  }
  
  /* .ant-menu > .ant-menu-item:hover,
  .ant-menu > .ant-menu-submenu:hover,
  .ant-menu > .ant-menu-item-active,
  .ant-menu> .ant-menu-submenu-active,
  .ant-menu > .ant-menu-item-open,
  .ant-menu> .ant-menu-submenu-open,
  .ant-menu > .ant-menu-item-selected,
  .ant-menu > .ant-menu-submenu-selected {
    color: rgb(5, 191, 21) !important;
    border-bottom: 2px solid red !important;
  }
  .ant-tabs-ink-bar {
    background: #b91e1e !important;
  }
  .ant-tabs-tab-btn {
    color: #231a52 !important;
  }
  .ant-dropdown-menu-item:hover{
    background-color: #231a52;
  }
  .ant-dropdown-menu-title-content:hover{
    color: #fff !important;
    transform: scale(1.02);
  } */
  .ant-avatar:hover{
    background-color: #231a52;
  }
  .sider-logo{
    width: 150px;
    margin: auto;
    display: flex;
    padding: 15px;
  }

  .ant-drawer-mask {
    background: rgba(0, 0, 0, 0.01);
  }
  .site-page-header{
    color: #fff;
  }
  /* .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #000000;
    background-color: #fff;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #000000;
  background-color: #fff;
}
.ant-menu-horizontal > .ant-menu-item-selected a:focus {
  border-bottom: none;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff;
} */

/* .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: none;
}
.ant-menu-item-not-selected{
  background-color: rgb(243, 152, 152);
} */

.ant-layout-footer{
  padding: 5px;
}

.ant-drawer{
  width: 40%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 60%;
}

.gmnoprint{
  display: none;
}
.ant-checkbox-group {
  display: flex;
  flex-direction: column;
}