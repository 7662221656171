.ant-btn-primary {
    background: #fff;
    color: #000;
    border: 1px solid rgb(218, 209, 209);
    outline: none;
   
}
@media only screen and (max-width: 992px) {
    .ant-btn-primary {
       padding: 0px 5px;
       font-size: 14px;
    }
}
.ant-btn-primary:hover,
.ant-btn-primary:focus ,
.ant-btn-primary:active  {
    background: #36B66A;
    color: #fff;
    border: 1px solid #36B66A;
}

.site-layout .site-layout-background {
  background: #fff;
}
  [data-theme="dark"] .site-layout .site-layout-background {
    background: #36B66A;
  }
  .site-layout{
    background-color: #fff;
    
  }

/* .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
    border-right-color: #ED1C24;
    border-left-color: #ED1C24;
  }
  .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
    border-color: #d9d9d9;
  }
  .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: #ED1C24;
  } */
  .glow-on-hover {
    width: 55px;
    height: auto;
    border: none;
    outline: none;
    background: rgb(253, 253, 253);
    position: relative;
    z-index: 0;
    /* border-radius: 10px; */
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(1px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    /* border-radius: 10px; */
}

.glow-on-hover:active {
    color: #000
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
    left: 0;
    top: 0;
    /* border-radius: 10px; */
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}