.App {
    width: 200px;
    height: 100px;
  }
  .container{
    z-index: 1000000000;
    /* background-color: rgb(0, 0, 0); */
  }
  .App {
    font-family: sans-serif;
    text-align: center;
  }
  .elements {
    /* display: grid;
    grid-template-rows: repeat(1, 100px);
    grid-row-gap: 20px;
    max-width: auto;
    box-sizing: border-box;
    padding: 20px;
    margin: 0 auto;
    border-radius: 10px;
    background: rgb(218, 23, 23); */
   
  }
  .elements-row {
    /* display: flex; */
    /* height: auto;
    display: grid;
    grid-template-columns: repeat(2, 20px);
    grid-gap: 10px; */
  }
  .element {
    display: grid;
    z-index: 11;
    text-align: right;
    /* border-radius: 5px; */
    padding: 10px 20px;
    margin: 20px 0px;
  }
  .element1 {
    background: Blue;
    cursor: pointer;
  }

  .element2 {
    background: Orange;
  }
  .element3 {
    background: Green;
  }
  .element4 {
    background: Brown;
  }
  .element5 {
    background: teal;
  }
  .element6 {
    background: rgb(64, 62, 62);
  }
  .element7 {
    background: Red;
  }
  .element8 {
    background: Black;
  }
  .element9 {
    background: Yellow;
  }
  .element10 {
    background: Violet;
  }
  .element11 {
    background: Pink;
  }
  .element12 {
    background: rgb(20, 70, 96);
  }
  .element13 {
    background: Pink;
  }
  .element14 {
    background: Violet;
  }
  .element15 {
    background: Yellow;
  }
  .element16 {
    background: Black;
  }
  .element17 {
    background: Red;
  }
  .element18 {
    background: White;
  }
  .element19 {
    background: teal;
  }
  .element20 {
    background: Brown;
  }
  .element21 {
    background: Green;
  }
  .element22 {
    background: Orange;
  }
  .element23 {
    background: Blue;
  }
  .element24 {
    background: teal;
  }
  .element43 {
    background: rgb(128, 0, 85);
  }
  
  .hoverClass {
    color: 'green'
  }
  .hoverClass:hover {
    color: 'pink'
  }